import { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import constants from "../../utils/constants";

const useLogin = () => {
  const [logged, setLogged] = useState(false);
  const [noAuth, setNoAuth] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    setUserId(window.localStorage.getItem(constants.id));
    window.localStorage.getItem(constants.role);
    setRole(JSON.parse(window.localStorage.getItem(constants.role) || "null"));
  }, [window.localStorage.getItem(constants.id)]);

  useEffect(() => {
    if (LoginService.isLoggedIn()) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, [logged]);

  const signIn = async (email, password) => {
    const cleanedEmail = email.trim(); // Remove whitespace
    const result = await LoginService.signIn(cleanedEmail, password);
    if (result) {
      setLogged(result);
    } else {
      setNoAuth(result);
    }
  };

  const reset = () => {
    setNoAuth(null);
  };

  return {
    logged,
    noAuth,
    signIn,
    reset,
    userId,
    role,
  };
};

export { useLogin };

import React from "react";
import { Box, Typography, Modal as MUIModal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #E8E8E8",
  mb: 2,
  pb: 1,
};

const Modal = ({ children, state, setState, title = "Advertencia", showHeader = true, showBottonCancel = true }) => {
  return (
    <MUIModal
      open={state}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        {showHeader && (
          <Box sx={headerStyle}>
            <Typography id="modal-title" variant="h6" component="h3" color="primary">
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setState(false)}
              sx={{ color: "primary.main" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {children}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        {showBottonCancel && (
          <Button onClick={() => setState(false)} variant="contained">
            Cerrar
          </Button>
        )}
        </Box>
      </Box>
    </MUIModal>
  );
};

export default Modal;
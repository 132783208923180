import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import { Button, Divider } from '@mui/material';
import useCourses from './Courses.services';
import SyllabusEditor from './SyllabusEditor';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CourseNew = () => {

  const {
    courseDetail,
    updateName,
    updateSPTR,
    updateSuccess,
    updateFailure,
    createCourse,
    originalDataChanged,
    handleClose,
    addSyllabusElement,
    removeSyllabusElement,
    updateSyllabus,
    setCardEditing,
    onEditSubitemName,
    onRemoveSubitem,
    onAddSubitem,
    redirect,
  } = useCourses(1);


  const [openSubItemDialog, setOpenSubItemDialog] = useState(false);
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [cardSubItemEditing, setCardSubItemEditing] = useState({ parentId: -1, id: -1, name: "" });
  const [cardItemEditing, setCardItemEditing] = useState({ id: -1, name: "" });

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Datos generales
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Nombre del curso"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={courseDetail.name}
                    onChange={(event) => {
                      updateName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="sptr"
                    name="sptr"
                    label="SPTR (opcional)"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={courseDetail.sptr ?? ''}
                    onChange={(event) => {
                      updateSPTR(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DndProvider backend={HTML5Backend}>
                    <SyllabusEditor
                      syllabus={courseDetail.syllabus ?? []}
                      addSyllabusElement={addSyllabusElement}
                      removeSyllabusElement={removeSyllabusElement}
                      updateSyllabus={updateSyllabus}
                      onEditItem={(card) => {
                        setCardItemEditing(card)
                        setOpenItemDialog(true);
                      }}
                      onEditSubitem={(itemId, subItem) => {
                        // onEditSubitem
                        onEditSubitemName(subItem);
                        setCardSubItemEditing({ parentId: itemId, ...subItem });
                        setOpenSubItemDialog(true);
                      }}
                      onRemoveSubitem={onRemoveSubitem}
                      onAddSubitem={onAddSubitem}
                    />
                  </DndProvider>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider />
                  <Button
                    disabled={!originalDataChanged}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => { createCourse() }}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={updateSuccess} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Curso creado <Link style={{ color: 'white' }} to={redirect} >Ver detalle</Link>
        </Alert>
      </Snackbar>
      <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error, no se pudo crear el curso
        </Alert>
      </Snackbar>
      <Dialog open={openSubItemDialog} onClose={() => setOpenSubItemDialog(false)}>
        <DialogTitle>Editar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ahora puedes modificar el elemento que has seleccionado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Sub Item"
            type="email"
            fullWidth
            variant="standard"
            placeholder='Nuevo subtema'
            value={cardSubItemEditing.name ?? ""}
            onChange={(event) => {
              setCardSubItemEditing({
                ...cardSubItemEditing,
                name: event.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSubItemDialog(false)}>Cancelar</Button>
          <Button onClick={() => {
            let tempSyllabus = JSON.parse(JSON.stringify(courseDetail.syllabus));
            /// modify on temporal the subitem edited by form
            const processSubItems = (parent) => {
              const newList = parent.subItem.map((subitem) => {
                return subitem.id != cardSubItemEditing.id
                  ? subitem
                  : cardSubItemEditing;
              });
              parent.subItem = newList;
              return parent;
            }

            let newSyllabus = tempSyllabus?.map((parentElement) => {
              return parentElement.id != cardSubItemEditing.parentId
                ? parentElement
                : processSubItems(parentElement);
            });

            updateSyllabus(newSyllabus)
            setOpenSubItemDialog(false);
          }}>Aceptar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openItemDialog} onClose={() => setOpenItemDialog(false)}>
        <DialogTitle>Editar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ahora puedes modificar el elemento que has seleccionado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nuevo tema"
            type="email"
            fullWidth
            variant="standard"
            placeholder='Nuevo tema'
            value={cardItemEditing.name ?? ""}
            onChange={(event) => {
              setCardItemEditing({
                ...cardItemEditing,
                name: event.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenItemDialog(false)}>Cancelar</Button>
          <Button onClick={() => {
            let tempSyllabus = JSON.parse(JSON.stringify(courseDetail.syllabus));

            let newSyllabus = tempSyllabus?.map((ItemElement) => {
              return ItemElement.id === cardItemEditing.id
                ? cardItemEditing
                : ItemElement;
            });

            updateSyllabus(newSyllabus)
            setOpenItemDialog(false);
          }}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CourseNew;

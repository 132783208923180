import config from '../config/config';
import constants from '../utils/constants';
import { callService } from "./CommonService";

const APIUrl = `${config.apiHost}${config.apiBase}auth/login`


function isLoggedIn() {
    if (window.localStorage.getItem(constants.tokenKey) !== null) {
        return true;
    }
    return false;
}

async function logout() {
    window.localStorage.removeItem(constants.tokenKey);
    window.localStorage.removeItem(constants.id);
}

async function signIn(email, password) {
    const response = await fetch(APIUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api': config.apiKey,
        },
        body: JSON.stringify({ email, password }),
    })
        .then((response) => {
            if (response.status != 200) {
                return null;
            }
            return response.json()
        })
        .then((data) => {
            if (!data) {
                return false;
            }
            window.localStorage.setItem(constants.tokenKey, data.token);
            window.localStorage.setItem(constants.id, data.user.id);
            window.localStorage.setItem(constants.role, JSON.stringify(data.user.role)); 
            return true;
        });

    return response;
}

async function sendRecovery(email) {
    const url = APIUrl + '/recover';
    const method = 'PATCH';
    const payload = {
        email: email,
    };
    return await callService(url, method, payload);

}

async function tokenConfirm(token) {
    const url = APIUrl + '/recoverConfirm';
    const method = 'PATCH';
    const payload = {
        token,
    };
    return await callService(url, method, payload);
}

async function updatePassword(userId, data) {
    const url = APIUrl + '/changePassword/' + userId;
    const method = 'PATCH';
    const payload = {
        password: data.password,
    }
    return await callService(url, method, payload);
}


export default { signIn, isLoggedIn, logout, sendRecovery, tokenConfirm, updatePassword };

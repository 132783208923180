import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useMigrationsNew, useStudentMigrate } from "./MigrationsNew.services";
import { Autocomplete, Button, Divider } from '@mui/material';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Modal from '../modal/Modal';
import StudentCoursesTable from '../studentDetail/StudentDetailCoursesTable';
import Logo from '../logo/logo';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const toItemList = (student) => {
  if (!student) return '';
  return `${student.id} - ${student.completeName} - ${student.company} - ${student.shift}`;
}

const MigrationsNew = () => {

  const [stateModalStudent, setStateModalStudent] = useState(false);


  const {
    studentMigrateDetails,
    studentDestinationDetails,
    updateSuccess,
    updateFailure,
    handleClose,
    selectedStudentMigrate,
    setSelectedStudentMigrate,
    selectedStudentDestination,
    setSelectedStudentDestination,
    migrateStudent,
    getStudents,
    redirect,
    students,
    isLoading,
  } = useMigrationsNew();

  useEffect(() => {
    getStudents();
  }, []);



  return isLoading
    ? <Logo />
    : <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Migración de participantes
              </Typography>
              <Grid container spacing={3}>  {/* Grid principal */}
                <Grid item xs={12} >
                  <p>Selecciona el participante que desea migrar: </p>
                  <Autocomplete
                    placeholder="Búsqueda de participante por nombre, correo electrónico, o compañía"
                    onInputChange={(event, newInputValue) => {
                      let studentMigrate = students.filter(studentMigrate => toItemList(studentMigrate) === newInputValue) ?? []
                      if (studentMigrate.length > 0) {
                        setSelectedStudentMigrate(studentMigrate[0]);

                      }
                    }}
                    id="controllable-states-demo"
                    options={students.map((studentMigrate) => toItemList(studentMigrate))}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Participante a migrar" />}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <StudentCoursesTable
                      title={'Cursos'}
                      rows={studentMigrateDetails.trainings}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} >
                  <p>Selecciona al participante destino:</p>
                  <Autocomplete
                    onInputChange={(event, newInputValue) => {
                      let studentFinal = students?.filter(studentFinal => toItemList(studentFinal) === newInputValue) ?? []
                      if (studentFinal.length > 0) {
                        setSelectedStudentDestination(studentFinal[0]);
                      }
                    }}
                    id="controllable-states-demo"
                    options={students.map((studentFinal) => toItemList(studentFinal))}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Participante Destino" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <StudentCoursesTable
                      title={'Cursos'}
                      rows={studentDestinationDetails.trainings}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider />
                  <Button
                    disabled={selectedStudentMigrate == null || selectedStudentDestination == null}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => { setStateModalStudent(!stateModalStudent) }}
                  >
                    Migrar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={updateSuccess} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Migración realizada <Link style={{ color: 'white' }} to={redirect}>Ver detalle</Link>
        </Alert>
      </Snackbar>
      <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error, no se pudo migrar al participante
        </Alert>
      </Snackbar>
      <Modal state={stateModalStudent} setState={setStateModalStudent} showHeader={true} showBottonCancel={false}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="h1" sx={{ mb: 2, fontWeight: "bold" }}>
            ¿Está seguro que desea realizar la migración?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            El participante a migrar será eliminado.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={
                () => {
                  migrateStudent();
                  setStateModalStudent(!stateModalStudent);
                }
              }
            >
              Aceptar
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setStateModalStudent(!stateModalStudent)}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
};

export default MigrationsNew;

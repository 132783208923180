import React, { useState, useEffect } from 'react';
import { DialogContentText } from '@mui/material';
import TextField from '@mui/material/TextField';
import Title from '../dashboard/Title';
import { DataGrid, esES } from '@mui/x-data-grid';
import * as locales from '@mui/material/locale';
import { ThemeProvider } from '@emotion/react';
import { Typography, createTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';

import useTrainings from './Trainings.services';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const theme = createTheme({}, esES);

const ParticipantsDataGrid = ({ headers, rows, onSelectionChange }) => {

  const handleSelectionChange = (check) => {
    const selectedStudents = rows.filter(row => check.includes(row.id));
    onSelectionChange(selectedStudents);
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <DataGrid
        rows={rows}
        disableRowSelectionOnClick={false}
        columns={headers.map((headerItem, _) => headerItem)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 8 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={true}
        onRowSelectionModelChange={handleSelectionChange}
      />
    </ThemeProvider>
  );
};

function createHeaderItem(field, headerName, width, renderCell, type) {

  const item = {
    field,
    headerName,
    width,
    renderCell,
    type: type ?? 'string',
  }
  return item;
}

function StudentsTable({
  title,
  headers,
  rows,
  onGetSelected,
}) {

  return (
    <React.Fragment>
      <Title>{title}</Title>
      {
        rows.length == 0
          ?
          <Grid item xs={12} justifyContent="center" alignItems="center" display="flex">
            <Typography>No se han encontrado participantes</Typography>
          </Grid>

          : <ParticipantsDataGrid headers={
            [
              createHeaderItem('id', 'Id', 50),
              createHeaderItem('completeName', 'Nombre', 300),
              createHeaderItem('curp', 'CURP', 220),
              createHeaderItem('rfc', 'RFC', 170),
              createHeaderItem('company', 'Compañia', 200),
              createHeaderItem('shift', 'Guardia', 200),
            ]
          }
            rows={rows}
            onSelectionChange={onGetSelected} />
      }
    </React.Fragment>
  );
}

export default function DialogComponent({ open, onClose, addStudent }) {

  const [selectedStudents, setSelectedStudents] = useState([]);

  const {
    updateParamSearch,
    selectedByParam,
    studentsListByParam,
    setSelectedByParam,

  } = useTrainings();




  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={() => { setSelectedByParam(null); onClose() }}
      addStudent={addStudent}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Agregar Participantes por filtro</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ingrese la guardia, el nombre del participante o la Compañía para iniciar la búsqueda.
        </DialogContentText>
        <Paper sx={{ margin: 'auto', overflow: 'hidden', marginTop: 2 }}>
          <AppBar

            position="static"
            color="default"
            elevation={0}
            sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs >
                <TextField
                  id="params"
                  name="params"
                  label="Guardia, Nombre o Compañía"
                  fullWidth
                  autoComplete="shift"
                  variant="standard"
                  type='search'
                  value={selectedByParam ?? ""}
                  onChange={(event) => {
                    updateParamSearch(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </AppBar>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 520 }}>
                <StudentsTable
                  title={'Participantes'}
                  headers={['Nombre', 'CURP', 'RFC']}
                  rows={studentsListByParam}
                  onGetSelected={setSelectedStudents}
                />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Grid sx={{ justifyContent: "right", display: "flex", marginTop: 3, marginBottom: 0 }}>
          <Button variant="contained" color="primary" sx={{ marginRight: 2 }}
            onClick={() => { addStudent(selectedStudents); setSelectedStudents(null); onClose(); }}>
            Agregar participantes
          </Button>
          <Button variant="contained" color="secondary"
            onClick={() => { setSelectedStudents(null); setSelectedByParam(null); onClose(); }}>
            Cancelar
          </Button>

        </Grid>
      </DialogContent>
    </Dialog>
  )
};

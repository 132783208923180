import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'students'; 

/* Retrieve list of all of guest in the system */
async function getStudents() {
    const url = APIUrlBase;
    return await callService(url);
}

/* Retrieve one single user data */ 
async function getStudent(studentId) {
    const url = APIUrlBase + '/' + studentId;
    return await callService(url);
}

/* Call API service to create a user */
async function createStudent(data) {
    const url = APIUrlBase;
    const method = 'POST';
    const payload = {
        name: data.name,
        lastname: data.lastname,
        rfc: data.rfc,
        curp: data.curp,
        companyId: data.company?.id,
        shift: data.shift,
    }
    return await callService(url, method, payload);
}

/* Call API service to update a user */
async function updateStudent(studentId, data) {
    const url = APIUrlBase + '/' + studentId;
    const method = 'PATCH';
    const payload = {
        name: data.name,
        lastname: data.lastname,
        rfc: data.rfc === '' ? null : data.rfc,
        curp: data.curp === '' ? null : data.curp,
        companyId: data.company?.id ?? null,
        shift: data.shift === '' ? null : data.shift,
    }
    return await callService(url, method, payload);
}

/* Call API service to migrate a user */
async function migrateStudent(studentOrigin,studentDestination) {
    const url = APIUrlBase+'/migrateStudent';
    const method = 'PUT';
    const payload = {
        studentOriginId: studentOrigin.id,
        studentDestinationId: studentDestination.id,
    }
    return await callService(url, method, payload);
}

export default { getStudents, getStudent, updateStudent, createStudent, migrateStudent };

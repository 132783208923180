import React, { useState } from 'react';
import { Navigate, Link as LinkRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import style from '../../pages/signin/SignIn.module.css';
import Modal from '../modal/Modal';

// service 
import { useRecover } from './SendRecovery.services';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Laser Application
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function TokenConfirm() {

  const { logged, noAuth, tokenConfirm, reset } = useRecover()
  const [stateModalCancel, setStateModalCancel] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    tokenConfirm(data.get('token'));
  };

  return (
    logged
      ? <Navigate to="/admin" />
      : <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            {noAuth !== null && noAuth === false
              ? <Alert variant="outlined" severity="warning">Código inválido</Alert>
              : null
            }
          </Box>
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 3,
              paddingTop: 0,
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{
              borderBottom: "1px solid #E8E8E8",
              alignItems: "center",
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              pb: 1,
            }}>
              <img className={style.logo2} src='../laser.png' />
              <Typography component="h2" variant='h5'>
                Confirmar código
              </Typography>
            </Box>
            <Typography variant="body1">
              Se ha enviado el código de recuperación a su correo electrónico.
              Ingrese el código para restablecer su contraseña.
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="token"
                label="Código de recuperación"
                name="token"
                autoComplete="token"
                autoFocus
                onChange={reset}
              />
              <Box sx={{ display: "flex", justifyContent: "center", gap: 8 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Confirmar
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => { setStateModalCancel(!stateModalCancel) }}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />

          <Modal state={stateModalCancel} setState={setStateModalCancel} showBottonCancel={false}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h5" component="h1" sx={{ mb: 2, fontWeight: "bold" }}>
                ¿Seguro que quiere cancelar esta operación?
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Será redirigido a la página de inicio de sesión.
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                <LinkRouter to="/login" style={{ textDecoration: 'none' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      () => {
                        setStateModalCancel(!stateModalCancel);
                      }
                    }
                  >
                    Aceptar
                  </Button>
                </LinkRouter>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => { setStateModalCancel(!stateModalCancel) }}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Modal>
        </Container>
      </ThemeProvider>
  );
}

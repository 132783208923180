import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useStudentNew } from "./StudentNew.services";
import { Autocomplete, Button, Divider } from '@mui/material';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Modal from '../modal/Modal';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StudentNew = () => {

  const [stateModalStudent, setStateModalStudent] = useState(false);

  const {
    studentDetails,
    updateSuccess,
    updateFailure,
    handleClose,
    originalDataChanged,
    updateCURP,
    updateName,
    updateLastname,
    updateRFC,
    createStudent,
    redirect,
    companies,
    studentExist,
    updateCompany,
    updateShift,
  } = useStudentNew();

  return (<Box
    component="main"
    sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    }}
  >
    <Toolbar />
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Datos generales
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Nombre"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  value={studentDetails.name}
                  helperText={studentExist ? "Este participante ya existe" : null}
                  error={studentExist}
                  onChange={(event) => {
                    updateName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="lastname"
                  name="lastname"
                  label="Apellido"
                  fullWidth
                  autoComplete="given-lastname"
                  variant="standard"
                  value={studentDetails.lastname}
                  helperText={studentExist ? "Este participante ya existe" : null}
                  error={studentExist}
                  onChange={(event) => {
                    updateLastname(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  id="curp"
                  name="curp"
                  label="CURP (opcional)"
                  fullWidth
                  autoComplete="curp"
                  variant="standard"
                  helperText={studentDetails.curp?.length != 18 ? "Debe contener 18 caracteres" : null}
                  error={studentDetails.curp && studentDetails.curp.length != 18}
                  value={studentDetails.curp ?? ""}
                  onChange={(event) => {
                    updateCURP(event.target.value);
                  }}
                  inputProps={{ maxLength: 18, minLength: 18 }}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  id="rfc"
                  name="rfc"
                  label="RFC (opcional)"
                  fullWidth
                  autoComplete="rfc"
                  variant="standard"
                  value={studentDetails.rfc ?? ""}
                  helperText={!([12, 13].includes(studentDetails.rfc?.length ?? 0)) ? "Debe contener 12 o 13 caracteres" : null}
                  error={studentDetails.rfc && ![12, 13].includes(studentDetails.rfc.length)}
                  onChange={(event) => {
                    updateRFC(event.target.value);
                  }}
                  inputProps={{ maxLength: 13, minLength: 12 }}
                />
              </Grid>

              <Grid item xs={12} >
                <Autocomplete
                  value={studentDetails.company?.name ?? ""}
                  onInputChange={(event, newInputValue) => {
                    let company = companies.filter(company => company.name == newInputValue)
                    if (company.length > 0) {
                      updateCompany(company[0]);
                    }
                  }}
                  id="controllable-states-demo"
                  options={companies.map(company => company.name)}
                  renderInput={(params) => <TextField {...params} label="Compañía (opcional)" />}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  id="shift"
                  name="shift"
                  label="Turno (opcional)"
                  fullWidth
                  autoComplete="shift"
                  variant="standard"
                  value={studentDetails.shift ?? ""}
                  onChange={(event) => {
                    updateShift(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Divider />
                <Button
                  disabled={!originalDataChanged ?? ""}
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={() => { studentExist ? setStateModalStudent(!stateModalStudent) : createStudent() }}
                >
                  Crear
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    <Snackbar open={updateSuccess} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Participante creado <Link style={{ color: 'white' }} to={redirect}>Ver detalle</Link>
      </Alert>
    </Snackbar>
    <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Error, no se pudo crear participante
      </Alert>
    </Snackbar>
    <Modal state={stateModalStudent} setState={setStateModalStudent} showHeader={true} showBottonCancel={false}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5" component="h1" sx={{ mb: 2, fontWeight: "bold" }}>
          Atención
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Este participante ya existe, ¿Estás seguro de que deseas crear este Participante?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={
              () => {
                createStudent();
                setStateModalStudent(!stateModalStudent);
              }
            }
          >
            Aceptar
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setStateModalStudent(!stateModalStudent)}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  </Box>
  );
};

export default StudentNew;

import React from 'react';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import style from '../../pages/signin/SignIn.module.css';
import { useParams } from 'react-router';
import Modal from '../modal/Modal';
import { Link as LinkRouter } from 'react-router-dom';

// service 
import { useChangePassword } from './ChangePassword.services';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Laser Application
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
} 

const theme = createTheme();

export default function ChangePassword() {

  
  const { id } = useParams();
  
  const { 
    logged, 
    noAuth, 
    passwordState,
    setFirstPassword,
    setSecondPassword,
    updatePassword,
    setStateModalPassword,
    stateModalPassword,
    passwordNotMatch, 
  } = useChangePassword(id)

  return (
    logged 
    ? <Navigate to="/admin" /> 
    : <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          { noAuth !== null && noAuth === false
            ? <Alert variant="outlined" severity="warning">Correo inválido</Alert>
            : null
          }
        </Box>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            paddingTop: 0, 
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{
            borderBottom: "1px solid #E8E8E8",
            alignItems: "center",
            display: 'flex',
            flexDirection: 'column',
            mb: 2,
            pb: 1,
          }}>
            <img className={style.logo2} src='../../laser.png' />
            <Typography component="h2" variant='h5'>
                Restablecer Contraseña
            </Typography>
          </Box>  
          <Typography variant="body1">
                Ingrese una nueva contraseña:  
            </Typography>
          
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              id="newPassword"
              label="Nueva Contraseña"
              type='password'
              autoComplete="newPassword"
              error={passwordNotMatch}
              helperText={ passwordNotMatch ? 'Las contraseñas no coinciden': ''}
              value={passwordState.password}
              onChange={(event) => {
                setFirstPassword(event.target.value);
              }}
            />
            <Typography variant="body1">
                Confirme la contraseña:  
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              label="Confirmar Contraseña"
              type="password"
              id="passwordConfirm"
              autoComplete="current-password"
              error={passwordNotMatch}
              helperText={passwordNotMatch ? 'Las contraseñas no coinciden': ''}
              value={passwordState.password2}
              onChange={(event) => {
                setSecondPassword(event.target.value);
              }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={()=> {updatePassword();}}
            >
              Cambiar Contraseña
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Modal state={stateModalPassword} setState={setStateModalPassword} showHeader={false} showBottonCancel={false}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5" component="h1" sx={{ mb: 2, fontWeight: "bold" }}>
              Cambio de contraseña exitoso
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Se ha cambiado tu contraseña correctamente. Puede oprimir el botón aceptar para continuar.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <LinkRouter to = "/login" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                      () => {
                          setStateModalPassword(!stateModalPassword);
                          } 
                  }
                >
                  Aceptar
                </Button>
              </LinkRouter>
            </Box>
          </Box>
        </Modal>
      </Container>
    </ThemeProvider>
  );
}

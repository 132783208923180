import { useState, useEffect } from 'react';
import LoginService from '../../services/LoginService';

let initialPasswordState = {
    password: "",
    password2: "",
};

const useChangePassword = (userId) => {
    const [logged, setLogged] = useState(false);
    const [noAuth, setNoAuth] = useState(null);
    const [passwordState, setPasswordState] = useState(initialPasswordState);
    const [passwordNotMatch, setPasswordNotMatch] = useState(false);

    const [stateModalPassword,setStateModalPassword] = useState(false);
    

    useEffect(() => {
        if (LoginService.isLoggedIn()) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [logged]);

    const setFirstPassword = (value) => {
        setNoAuth(null);
        setPasswordNotMatch(false);
        setPasswordState({
            ...passwordState,
            password: value,
        })
    };

    const setSecondPassword = (value) => {
        setNoAuth(null);
        setPasswordNotMatch(false);
        setPasswordState({
            ...passwordState,
            password2: value,
        })
    };

    const updatePassword = async () => {
        if (passwordState.password === passwordState.password2) {
            const changeSuccesfull = await LoginService.updatePassword(userId, {
                password: passwordState.password,   
            });
            if (changeSuccesfull) {
                setPasswordState(initialPasswordState);
                setStateModalPassword(!stateModalPassword);
            } else {
                setNoAuth(false); 
            }
        } else {
            setPasswordNotMatch(true);
        }
    };



    return {
        logged,
        noAuth,
        passwordState,
        setFirstPassword,
        setSecondPassword,
        updatePassword,
        setStateModalPassword,
        stateModalPassword,
        passwordNotMatch,
    };
}

export { useChangePassword};

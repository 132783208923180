import { useReducer, useState, useEffect } from "react";
import ProfileService from "../../services/ProfileService";

let initialState = {
    name: "",
    email: "",
};

let initialPasswordState = {
    password: "",
    password2: "",
};

const Actions = {
    INITIAL: "INITIAL",
    NAME: "NAME",
    EMAIL: "EMAIL",
};

const reducer = (state, payload) => {
    switch (payload.action) {
        case Actions.INITIAL:
            initialState = {
                name: payload.value.name,
                email: payload.value.email,
                type: payload.value.role.name,
                isActive: payload.value.isActive,
            }
            return initialState
        case Actions.NAME:
            return {
                ...state,
                name: payload.value,
            }
        case Actions.EMAIL:
            return {
                ...state,
                email: payload.value,
            }
        default:
            return state
    }
}

const options = ['Administrador', 'Operador'];

const useProfile = (userId) => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [passwordState, setPasswordState] = useState(initialPasswordState);
    const [passwordNotMatch, setPasswordNotMatch] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const setFirstPassword = (value) => {
        setPasswordNotMatch(false);
        setPasswordState({
            ...passwordState,
            password: value,
        })
    };

    const setSecondPassword = (value) => {
        setPasswordNotMatch(false);
        setPasswordState({
            ...passwordState,
            password2: value,
        })
    };



    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleClickCloseDialog = () => {
        setPasswordState(initialPasswordState);
        setOpenDialog(false);
    }

    const updatePassword = async () => {
        if (passwordState.password === passwordState.password2) {
            const response = await ProfileService.updateUser(userId, {
                ...userDetails,
                password: passwordState.password,
            });
            if (response) {
                setUpdateSuccess(true);
                setPasswordState(initialPasswordState);
                handleClickCloseDialog();
            } else {
                setUpdateFailure(true);
            }
        } else {
            setPasswordNotMatch(true);
        }
    };

    const getUser = async () => {
        const response = await ProfileService.getUser(userId);
        if (response) {
            setInitial(response);
        }
    };

    const updateUser = async () => {
        const response = await ProfileService.updateUser(userId, userDetails);
        if (response) {
            setInitial(response);
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true)
        }
    };

    const [userDetails, dispatch] = useReducer(reducer, initialState);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);

    useEffect(() => {
        originalDataHasChanged();
    }, [userDetails]);

    const originalDataHasChanged = () => {

        const hasChanged = userDetails.name !== initialState.name
            || userDetails.email !== initialState.email
            || userDetails.type !== initialState.type
            || userDetails.isActive != initialState.isActive

        setOriginalDataChanged(hasChanged);
    };

    const setInitial = (data) => {
        dispatch({ action: Actions.INITIAL, value: data });
    };

    const updateName = (name) => {
        dispatch({ action: Actions.NAME, value: name });
    };

    const updateEmail = (email) => {
        dispatch({ action: Actions.EMAIL, value: email });
    };


    const handleClick = () => {
        setUpdateSuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
    };

    return {
        userDetails,
        updateName,
        updateEmail,
        options,
        getUser,
        originalDataChanged,
        updateUser,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        // region password change
        passwordState,
        setFirstPassword,
        setSecondPassword,
        updatePassword,
        handleClickCloseDialog,
        handleClickOpenDialog,
        openDialog,
        // region change password validation
        passwordNotMatch,
    }
}

export { useProfile };

import React, { useEffect, useReducer, useState } from "react";
import TrainingService from "../../services/TrainingService";
import CourseService from "../../services/CourseService";
import InstructorService from "../../services/InstructorService";
import StudentService from "../../services/StudentService";
import DeputyService from "../../services/DeputyService";
import CompanyService from "../../services/CompanyService";

export const FlowType = {
    create: 'CREATE', details: 'DETAILS', list: 'LIST',
};

const steps = ['Elige un Curso', 'Fecha', 'Elige un Instructor', 'Folio', 'Participantes'];

const initialStartDate = new Date();
const initialEndDate = new Date();
initialEndDate.setDate(initialEndDate.getDate() + 1);

const initialTrainingData = {
    id: "",
    name: "",
    dateStart: initialStartDate,
    dateEnd: initialEndDate,
    code: "",
    spptrCode: "",
    course: {
        id: "",
        name: "",
        syllabus: [],
        sptr: "",
    },
    instructor: {
        id: "",
        name: "",
        lastname: "",
        completeName: "",
        instructorSTPS: "",
        spptr: "",
    },
    deputy: {
        id: "",
        name: "",
        lastname: "",
        completeName: "",
        position: "",
    },
    students: [],
    validity: "",
    totalHours: "",
};

let originalTrainingData = null;

function createCourseData(course) {
    return {
        id: course.id,
        name: course.name,
        syllabus: course.syllabus,
        sptr: course.sptr,
    };
}

function createInstructorData(instructor) {
    return {
        id: instructor.id,
        name: instructor.name,
        lastname: instructor.lastname,
        completeName: instructor.name + ' ' + instructor.lastname,
        instructorSTPS: instructor.instructorSTPS,
        spptr: instructor.spptr,
        conflicted: instructor.training_conflict,
    };
}

function parseCompanyResponse(response) {
    response.unshift({ id: -1, name: 'TODOS' })
    return response
}

function createDeputiesData(deputy) {
    return {
        id: deputy.id,
        name: deputy.name,
        lastname: deputy.lastname,
        completeName: deputy.name + ' ' + deputy.lastname,
        position: deputy.position,
    }
}

function createStudentData(student) {
    return {
        id: student.id,
        name: student.name,
        lastname: student.lastname,
        completeName: student.name + ' ' + student.lastname,
        rfc: student.rfc,
        curp: student.curp,
        company: student.company ? student.company.name : "Sin empresa",
        shift: student.shift ? student.shift : "Sin guardia",
    }
}

function createData(id, trainingName, dateStart, dateEnd, code) {
    return {
        id,
        trainingName,
        dateStart: new Date(dateStart),
        dateEnd: new Date(dateEnd),
        code,
    };
}

function responseToTrainingDetail(response) {
    let deputy = null;
    if (response.deputy) {
        deputy = {
            id: response.deputy.id,
            name: response.deputy.name,
            lastname: response.deputy.lastname,
            completeName: response.deputy.name + ' ' + response.deputy.lastname,
            position: response.deputy.position,
        }
    }
    return {
        id: response.id,
        dateStart: new Date(response.dateStart),
        dateEnd: new Date(response.dateEnd),
        code: response.code,
        spptrCode: response.spptrCode,
        course: {
            id: response.course.id,
            name: response.course.name,
            syllabus: response.course.syllabus,
            sptr: response.course.sptr,
        },
        instructor: {
            id: response.instructor.id,
            name: response.instructor.name,
            lastname: response.instructor.lastname,
            completeName: response.instructor.name + ' ' + response.instructor.lastname,
            instructorSTPS: response.instructor.instructorSTPS,
            spptr: response.instructor.spptr,
            conflicted: response.instructor.conflicted,
        },
        deputy: deputy,
        students: response.students,
        validity: response.validity,
        totalHours: response.totalHours ?? '',
    }
}


const Actions = {
    INIT: "INIT",
    SET_COURSE: "SET_COURSE",
    SET_INSTRUCTOR: "SET_INSTRUCTOR",
    SET_DEPUTY: "SET_DEPUTY",
    SET_DATE_START: "SET_DATE_START",
    SET_DATE_END: "SET_DATE_END",
    SET_CODE: "SET_CODE",
    SET_SPPTR_CODE: "SET_SPPTR_CODE",
    ADD_STUDENT: "ADD_STUDENT",
    ADD_STUDENT_BULK: "ADD_STUDENT_BULK",
    REMOVE_STUDENT: "REMOVE_STUDENT",
    REMOVE_STUDENT_BULK: "REMOVE_STUDENT_BULK",
    SET_VALIDITY: "SET_VALIDITY",
    SET_TOTAL_HOURS: "SET_TOTAL_HOURS",
}

const reducer = (state, payload) => {
    let students = state.students;
    switch (payload.action) {

        case Actions.INIT:
            const newValue = { ...payload.value }
            originalTrainingData = { ...payload.value };
            return newValue;
        case Actions.SET_COURSE:
            return {
                ...state,
                course: {
                    id: payload.value.id,
                    name: payload.value.name,
                    syllabus: payload.value.syllabus,
                    sptr: payload.value.sptr,
                }
            }
        case Actions.SET_INSTRUCTOR:
            return {
                ...state,
                instructor: {
                    ...payload.value,
                }
            }
        case Actions.SET_DEPUTY:
            return {
                ...state,
                deputy: {
                    ...payload.value,
                }
            }
        case Actions.SET_DATE_START:
            return {
                ...state,
                dateStart: payload.value,
            }
        case Actions.SET_DATE_END:
            return {
                ...state,
                dateEnd: payload.value,
            }
        case Actions.SET_CODE:
            return {
                ...state,
                code: payload.value,
            }
        case Actions.SET_SPPTR_CODE:
            return {
                ...state,
                spptrCode: payload.value,
            }
        case Actions.SET_VALIDITY:
            return {
                ...state,
                validity: payload.value,
            }
        case Actions.SET_TOTAL_HOURS:
            return {
                ...state,
                totalHours: payload.value,
            }
        case Actions.ADD_STUDENT:
            let newPayloadValue = payload.value;
            newPayloadValue = {
                companyId: newPayloadValue?.company?.id ?? null,
                ...newPayloadValue,
            };
            delete newPayloadValue.company;
            let newStudentList = [newPayloadValue, ...students];
            return {
                ...state,
                students: newStudentList,
            }
        case Actions.ADD_STUDENT_BULK:
            let studentList = payload.value;
            return {
                ...state,
                students: studentList,
            }
        case Actions.REMOVE_STUDENT_BULK:
            return {
                ...state,
                students: [],
            }
        case Actions.REMOVE_STUDENT:
            const newStudents = students.filter(s => s.id !== payload.value.id)
            return {
                ...state,
                students: newStudents,
            }
        default:
            return state

    }
};

const useTrainings = (flowType) => {
    const [openPDFDialog, setOpenPDFDialog] = React.useState(false);
    const [trainings, setTrainings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [students, setStudents] = useState([]);
    const [deputies, setDeputies] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [formTrainingDataState, dispatch] = useReducer(reducer, initialTrainingData);
    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [byCompany, setByCompany] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedByParam, setSelectedByParam] = useState(null);
    const [studentsListByParam, setStudentsListByParam] = useState([]);


    useEffect(() => {
        filterStudentsByParamSearch();
    }, [selectedByParam]);

    useEffect(() => {
        getCourses();
        getStudents();
        getDeputies();
    }, []);

    useEffect(() => {
        getInstructors();
        if (flowType == FlowType.create) {
            getCompanies();
            getNewFolio();
        }
    }, [formTrainingDataState.dateStart, formTrainingDataState.dateEnd]);

    useEffect(() => {
        hasDataChanged();
    }, [formTrainingDataState]);

    const hasDataChanged = () => {
        const hasChanged = JSON.stringify(formTrainingDataState) !== JSON.stringify(originalTrainingData)
        setDataHasChanged(hasChanged);
    };

    const getCompanies = async () => {
        const response = await CompanyService.getCompanies();
        if (response) {
            setCompanies(parseCompanyResponse(response));
        }
    };

    const getTrainings = async () => {
        const response = await TrainingService.getTrainings();
        if (response.length > 0) {
            const trainings = response.map((training) => createData(
                training.id,
                training.course.name,
                training.dateStart,
                training.dateEnd,
                training.code,
            ));
            setTrainings(trainings);

        }
        setIsLoading(false);
    }

    const getCourses = async () => {
        const response = await CourseService.getCourses();
        if (response.length > 0) {
            const courses = response.map((course) => createCourseData(course));
            setCourses(courses);
            setCourse(courses[0]);
        }
    };

    const getInstructors = async () => {
        const response = await InstructorService.getInstructors(formTrainingDataState.dateStart, formTrainingDataState.dateEnd);
        if (response.length > 0) {
            const instructors = response.map((instructor) => createInstructorData(instructor));
            setInstructors(instructors);
        }
    };

    const getNewFolio = async () => {
        const response = await TrainingService.generateFolio(formTrainingDataState.dateStart);
        if (response) {
            setCode(response.folio);
        }
    };

    const getStudents = async () => {
        const response = await StudentService.getStudents();
        const students = response.map((student) => createStudentData(student));
        setStudents(students);
    };

    const getDeputies = async () => {
        const response = await DeputyService.getDeputies();
        const deputies = response.map((deputy) => createDeputiesData(deputy));
        setDeputies(deputies);
    };

    const getTraining = async (trainingId) => {
        const response = await TrainingService.getTraining(trainingId);
        dispatch({ action: Actions.INIT, value: responseToTrainingDetail(response) });
    };

    const createTraining = async (trainingData) => {
        const response = await TrainingService.createTraining(trainingData);
        if (response) {
            for (let idx in trainingData.students) {
                let student = trainingData.students[idx];
                await TrainingService.addStudentToTraining(response.id, student.id);
            }
            setUpdateSuccess(true);
            setRedirect(`/admin/trainings/${response.id}`);
        } else {
            setUpdateFailure(true);
        }
        return response;
    };

    const contains = (element, array) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i] === element) {
                return true;
            }
        }
        return false;
    };

    const deleteTraining = async (trainingData) => {
        // update generals of training
        const response = await TrainingService.deleteTraining(trainingData.id);
    };

    const updateTraining = async (trainingData) => {
        // update generals of training
        const response = await TrainingService.updateTraining(trainingData.id, trainingData);

        //// update students data
        // setup
        const originalIds = originalTrainingData.students.map((student) => student.id);
        const newIds = trainingData.students.map((student) => student.id);

        // calculations
        // let existingStudentsIds = innerJoin(originalIds, newIds);
        let newStudentsIds = newIds.filter((id) => !contains(id, originalIds));
        let removedStudents = originalIds.filter((id) => !contains(id, newIds));;
        let responseAddNewStudent = true;
        let responseRemoveStudent = true;

        for (let idx in newStudentsIds) {
            responseAddNewStudent = await TrainingService.addStudentToTraining(trainingData.id, newStudentsIds[idx]);
        }

        for (let idx in removedStudents) {
            responseRemoveStudent = await TrainingService.removeStudentToTraining(trainingData.id, removedStudents[idx]);
        }

        if (response) {
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }

        if (responseAddNewStudent || responseRemoveStudent) {
            setUpdateSuccess(true);
        }
    };

    const onClickUpdate = () => {
        updateTraining(formTrainingDataState);
    }

    const onDeleteTraining = () => {
        deleteTraining(formTrainingDataState);
    }

    const openDialog = () => {
        setOpenPDFDialog(true);
    }

    const closeDialog = () => {
        setOpenPDFDialog(false);
    }

    const updateParamSearch = (ParamSearch) => {
        if (ParamSearch === '') {
            setSelectedByParam(null);
        } else {
            setSelectedByParam(ParamSearch);
        }

    }

    const filterStudentsByParamSearch = () => {

        if (selectedByParam != null) {
            const regex = new RegExp(selectedByParam, 'i');
            const studentsListByParam = students.filter(student =>
                regex.test(student.shift) || regex.test(student.name) || regex.test(student.company));
            setStudentsListByParam(studentsListByParam);
        } else {
            setStudentsListByParam([]);
        }

    }

    const handleNext = () => {
        const nextStep = activeStep + 1;
        // create training, send data through api
        if (nextStep == steps.length) {
            const response = createTraining(formTrainingDataState);
            if (response) {
                setUpdateSuccess(true);
            } else {
                setUpdateFailure(true);
            }
        } else {
            setActiveStep(nextStep);
        }
    };

    const reset = () => {
        // dispatch({ action: Actions.RESET });
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    // wizard course
    const setCourse = (course) => {
        dispatch({ action: Actions.SET_COURSE, value: course });
    }

    // wizard instructor
    const setInstructor = (instructor) => {
        dispatch({ action: Actions.SET_INSTRUCTOR, value: instructor });
    }

    // wizard deputy
    const setDeputy = (deputy) => {
        dispatch({ action: Actions.SET_DEPUTY, value: deputy });
    }

    // wizard instructor
    const setDateStart = (dateStart) => {
        dispatch({ action: Actions.SET_DATE_START, value: dateStart });
    }

    // wizard instructor
    const setDateEnd = (dateEnd) => {
        dispatch({ action: Actions.SET_DATE_END, value: dateEnd });
    }

    const setCode = (code) => {
        dispatch({ action: Actions.SET_CODE, value: code });
    }

    const setSPTTRCode = (spptrCode) => {
        dispatch({ action: Actions.SET_SPPTR_CODE, value: spptrCode });
    }

    const setValidity = (validity) => {
        dispatch({ action: Actions.SET_VALIDITY, value: validity });
    }

    const setTotalHours = (totalHours) => {
        dispatch({ action: Actions.SET_TOTAL_HOURS, value: totalHours });
    }

    const studentExists = (student) => {
        for (let i = 0; i < formTrainingDataState.students.length; i++) {
            if (formTrainingDataState.students[i].id == student.id) {
                return true;
            }
        }
        return false;
    }

    const studentExistsById = (studentId) => {
        for (let i = 0; i < formTrainingDataState.students.length; i++) {
            if (formTrainingDataState.students[i].id == studentId) {
                return true;
            }
        }
        return false;
    }

    const checkStudent = (id, checked) => {
        if (checked) {
            setSelectedStudents([...selectedStudents, id])
        } else {
            const index = selectedStudents.indexOf(id);
            selectedStudents.splice(index, 1);
            setSelectedStudents([...selectedStudents]);
        }
    }

    const addStudentBulk = () => {
        const studentList = [];
        for (const index in selectedStudents) {
            studentList.push(students.filter((s) => s.id == selectedStudents[index])[0]);
        }
        dispatch({ action: Actions.ADD_STUDENT_BULK, value: studentList });
    }

    const addStudent = (student) => {
        if (Array.isArray(student)) {
            student.map((student) => {
                if (studentExists(student)) return;
                dispatch({ action: Actions.ADD_STUDENT, value: student })
            })
            setSelectedByParam(null);
        } else {
            if (studentExists(student)) return;
            dispatch({ action: Actions.ADD_STUDENT, value: student });
        }
    }

    const removeStudentBulk = () => {
        setSelectedStudents([]);
        dispatch({ action: Actions.REMOVE_STUDENT_BULK, value: null });
    }

    const removeStudents = (student) => {
        const newSelectedList = selectedStudents.filter((id) => id != student.id);
        setSelectedStudents(newSelectedList);
        dispatch({ action: Actions.REMOVE_STUDENT, value: student });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const setFilterByCompany = (companyId) => {
        setByCompany(companyId);
    }

    return {
        trainings,
        getTrainings,
        getTraining,
        openPDFDialog,
        openDialog,
        closeDialog,
        steps,
        activeStep,
        handleNext,
        handleBack,
        // catalog Courses
        courses,
        setCourse,
        // catalog Instructors
        instructors,
        setInstructor,
        formTrainingDataState,
        setDateStart,
        setDateEnd,
        setCode,
        setSPTTRCode,
        setValidity,
        setTotalHours,
        students,
        addStudent,
        removeStudents,
        // Display success or error states
        updateSuccess,
        updateFailure,
        dataHasChanged,
        onClickUpdate,
        handleClose,
        deputies,
        setDeputy,
        redirect,
        companies,
        byCompany,
        setFilterByCompany,
        selectedStudents,
        setSelectedStudents,
        checkStudent,
        addStudentBulk,
        removeStudentBulk,
        isLoading,
        onDeleteTraining,
        updateParamSearch,
        selectedByParam,
        setSelectedByParam,
        studentsListByParam,
        filterStudentsByParamSearch,
    }
};

export default useTrainings;

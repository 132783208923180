import { useEffect, useReducer, useState } from "react";
import StudentService from "../../services/StudentService";
import CompanyService from "../../services/CompanyService";

var initialData = {
    id: "", 
    name: "",
    lastname: "",
    curp: null, 
    rfc: null, 
    company: null,
    shift: null,
};

function responseToStudentDetail(response) {
    return {
        id: response.id, 
        name: response.name,
        lastname: response.lastname,
        curp: response.curp, 
        rfc: response.rfc,
        company: response.company,
    }
}

const ACTION_INITIAL = "ACTION_INITIAL";
const ACTION_UPDATE_NAME = "ACTION_UPDATE_NAME";
const ACTION_UPDATE_LASTNAME = "ACTION_UPDATE_LASTNAME";
const ACTION_UPDATE_RFC = "ACTION_UPDATE_RFC";
const ACTION_UPDATE_CURP = "ACTION_UPDATE_CURP";
const ACTION_UPDATE_COMPANY = "ACTION_UPDATE_COMPANY";
const ACTION_UPDATE_SHIFT = "ACTION_UPDATE_SHIFT";

const reducer = (state, payload) => {
    switch(payload.action) {
        case ACTION_INITIAL :
            return payload.data;
        case ACTION_UPDATE_NAME :
            return {
                ...state,
                name: payload.data,
            }
        case ACTION_UPDATE_LASTNAME :
                return {
                    ...state,
                    lastname: payload.data,
                }
        case ACTION_UPDATE_RFC : 
            return {
                ...state,
                rfc: payload.data,
            }
        case ACTION_UPDATE_CURP :
            return {
                ...state,
                curp: payload.data,
            }
        case ACTION_UPDATE_COMPANY :
                return {
                    ...state,
                    company: payload.data,
                }
        case ACTION_UPDATE_SHIFT:
            return{
                ...state,
                shift: payload.data,
            }
        default:
            return state;
    }
}

const useStudentNew = () => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [students, setStudents] = useState([]); 
    const [studentExist, setStudentExist] = useState(false); 

    const [ studentDetails, dispatch ] = useReducer(reducer, initialData);

    useEffect(() => {
        getCompanies();
        getStudents();
      }, []);
      
    useEffect(() => {
        originalDataHasChanged();
    }, [studentDetails]);

    const originalDataHasChanged = () => {

        const hasChanged = studentDetails.name !== ""
            && studentDetails.lastname !== ""

        setOriginalDataChanged(hasChanged);
    };

    const getCompanies = async () => {
        const response = await CompanyService.getCompanies();
        if (response) {
            setCompanies(response);
        }
    };

    const getStudents = async () => {
        const response = await  StudentService.getStudents();
        if (response) {
            setStudents(response);
        }
    }

   const compareStudent = (name, lastname) =>{
        const studentExist = students.some(
            (students) =>
                students.name.toLowerCase().trim() === name.toLowerCase().trim() && 
            students.lastname.toLowerCase().trim() === lastname.toLowerCase().trim()
        )
        setStudentExist(studentExist);
    }

    const setInitialData = (data) => {
        dispatch({ action: ACTION_INITIAL, data: data });
    }
    
    const updateName = (name) => {
        compareStudent(name, studentDetails.lastname);
        dispatch({ action: ACTION_UPDATE_NAME, data: name });
    }

    const updateLastname = (lastname) => {
        compareStudent(studentDetails.name ,lastname);
        dispatch({ action: ACTION_UPDATE_LASTNAME, data: lastname });
    }

    const updateRFC = (rfc) => {
        dispatch({ action: ACTION_UPDATE_RFC, data: rfc });
    }

    const updateCURP = (curp) => {
        dispatch({ action: ACTION_UPDATE_CURP, data: curp });
    }

    const updateCompany = (company) => {
        dispatch({ action: ACTION_UPDATE_COMPANY, data: company });
    }
    
    const updateShift = (shift) => {
        dispatch({ action: ACTION_UPDATE_SHIFT, data: shift});
    }

    const handleClick = () => {
        setUpdateSuccess(true);
        setUpdateFailure(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const createStudent = async () => {
        const response = await StudentService.createStudent(studentDetails);
        if (response) {
            setUpdateSuccess(true);
            setInitialData(initialData);
            setRedirect(`/admin/students/${response.id}`);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        studentDetails,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        originalDataChanged,
        updateCURP,
        updateName,
        updateLastname,
        updateRFC,
        createStudent,
        redirect,
        companies,
        students,
        studentExist,
        updateCompany,
        updateShift,
        getCompanies,
        getStudents,
        compareStudent,
    }
};

export { useStudentNew };

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useProfile } from './Profile.services';
import { Button, Divider } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect } from 'react';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Profile = () => {

    const { id } = useParams();

    const {
        userDetails,
        updateName,
        updateEmail,
        getUser,
        originalDataChanged,
        updateUser,
        updateSuccess,
        updateFailure,
        handleClose,
        // region password change
        passwordState,
        setFirstPassword,
        setSecondPassword,
        updatePassword,
        handleClickCloseDialog,
        handleClickOpenDialog,
        openDialog,
        // region password validation
        passwordNotMatch,
    } = useProfile(id);



    useEffect(() => {
        getUser();
    }, []);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Recent Orders */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h5" gutterBottom>
                                Perfil de usuario
                            </Typography>
                            <Grid container spacing={3} marginTop={3}>
                                <Grid item display="flex" xs={12} alignItems="baseline">
                                    <Grid item xs={3}>
                                        <Typography variant="h6" >
                                            Nombre:
                                        </Typography>
                                    </Grid>
                                    <Grid item display="flex" xs={5} justifyContent="left" paddingRight={20}>
                                        <TextField
                                            id="name"
                                            required
                                            fullWidth
                                            name="name"
                                            autoComplete="given-name"
                                            variant="standard"
                                            value={userDetails.name}
                                            onChange={(event) => {
                                                updateName(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item display="flex"
                                        xs={4}
                                        justifyContent="center"
                                        color="default"
                                        elevation={0}
                                        sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', boxShadow: 1 }}
                                    >
                                        <Typography variant="h5">
                                            Tipo de usuario
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item display="flex" xs={12} alignItems="baseline" >
                                    <Grid item xs={3}>
                                        <Typography fullWidth variant="h6" >
                                            Correo electrónico:
                                        </Typography>
                                    </Grid>
                                    <Grid item display="flex" xs={5} justifyContent="left" paddingRight={20}>
                                        <TextField
                                            required
                                            id="email"
                                            fullWidth
                                            name="email"
                                            autoComplete="shipping address-line1"
                                            variant="standard"
                                            value={userDetails.email}
                                            onChange={(event) => {
                                                updateEmail(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item
                                        display="flex"
                                        xs={4}
                                        justifyContent="center"
                                        sx={{ my: 5, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                        <Typography variant="h6">
                                            {userDetails.type}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={!originalDataChanged}
                                            variant="contained"
                                            sx={{ mt: 3, ml: 1 }}
                                            onClick={() => { updateUser() }}
                                        >
                                            Actualizar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} display="flex" justifyContent="center">
                                        <Divider />
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 3, ml: 1 }}
                                            onClick={() => {
                                                handleClickOpenDialog();
                                            }}
                                        >
                                            Cambiar Contraseña
                                        </Button>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openDialog}
                onClose={handleClickCloseDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Cambiar contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ingrese una nueva contraseña y vuelva a confirmar la contraseña.
                    </DialogContentText>
                    <Paper sx={{ margin: 'auto', overflow: 'hidden', marginTop: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="newPassword"
                                        id="newPassword"
                                        label="Nueva Contraseña"
                                        type='password'
                                        autoComplete="newPassword"
                                        error={passwordNotMatch}
                                        helperText={passwordNotMatch ? 'Las contraseñas no coinciden' : ''}
                                        value={passwordState.password}
                                        onChange={(event) => {
                                            setFirstPassword(event.target.value);
                                        }}
                                    />
                                    <Typography variant="body1" marginTop={2}>
                                        Confirme la contraseña:
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="passwordConfirm"
                                        label="Confirmar Contraseña"
                                        type="password"
                                        id="passwordConfirm"
                                        autoComplete="current-password"
                                        error={passwordNotMatch}
                                        helperText={passwordNotMatch ? 'Las contraseñas no coinciden' : ''}
                                        value={passwordState.password2}
                                        onChange={(event) => {
                                            setSecondPassword(event.target.value);
                                        }}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid sx={{ justifyContent: "right", display: "flex", marginTop: 3, marginBottom: 0 }}>
                        <Button variant="contained" color="primary" sx={{ marginRight: 2 }}
                            onClick={() => { updatePassword(); }}>
                            Cambiar Contraseña
                        </Button>
                        <Button variant="contained" color="secondary"
                            onClick={() => { handleClickCloseDialog(); }}>
                            Cancelar
                        </Button>

                    </Grid>
                </DialogContent>
            </Dialog>
            <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Usuario actualizado
                </Alert>
            </Snackbar>
            <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Error, no se pudo actualizar al usuario
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Profile;

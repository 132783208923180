import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import useTrainings, { FlowType } from './Trainings.services';
import { SimpleDialog } from '../certificate/PDFDialog';
import ListItemText from '@mui/material/ListItemText';


import DialogComponent from './StudentsGroupDialog';
import style from './Training.module.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const toItemList = (student) => {
  if (!student) return '';
  return `${student.id} - ${student.completeName}`;
}

function Content({ students, addStudent, children }) {

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);


  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon color="inherit" sx={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Autocomplete
                placeholder="Búsqueda de participante por nombre, correo electrónico, o compañía"
                onInputChange={(event, newInputValue) => {
                  let student = students?.filter(student => toItemList(student) === newInputValue) ?? []
                  if (student.length > 0) {
                    setSelectedStudent(student[0]);
                  }
                }}
                options={students.map((student) => toItemList(student))}
                renderInput={(params) => <TextField {...params} variant="standard" label="" />}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => {
                  if (selectedStudent) {
                    addStudent(selectedStudent);
                    setSelectedStudent(null);
                  }
                }}
              >
                Agregar
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={handleClickOpenDialog}
              >
                Agregar por Turno
              </Button>
              <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon color="inherit" sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogComponent open={openDialog} onClose={handleCloseDialog} addStudent={addStudent} />
      </AppBar>
      {children}
    </Paper>
  );
}

const TrainingStudentsTable = ({ title, headers, rows, removeStudents, students, addStudent }) => (
  <React.Fragment>
    <Title>{title}</Title>
    <Content
      students={students}
      addStudent={addStudent}
    >
      {rows.length == 0 ?
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          No se han agregado participantes en esta capacitación.
        </Typography>
        :
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((headerItem, index) => <TableCell key={index}>
                  {headerItem}
                </TableCell>)}
                <TableCell >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.id}
                  </TableCell>
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {row.lastname}
                  </TableCell>
                  <TableCell>
                    {row.curp}
                  </TableCell>
                  <TableCell>
                    {row.company}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        removeStudents(row);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Content>
  </React.Fragment>
);

function setupCodeSPPTRField(formTrainingDataState, setCode) {
  return (
    <TextField
      style={{ width: '100%' }}
      id="outlined-basic"
      label="Clave SPPTR"
      variant="outlined"
      value={formTrainingDataState.spptrCode}
      onChange={event => {
        setCode(event.target.value)
      }} />
  );
}

function setupCodeField(formTrainingDataState, setCode) {
  return (
    <TextField
      style={{ width: '100%' }}
      id="outlined-basic"
      label="Clave del curso"
      variant="outlined"
      value={formTrainingDataState.code}
      onChange={event => {
        setCode(event.target.value)
      }} />
  );
}

function setupValidityField(formTrainingDataState, setValidity) {
  return (
    <TextField
      style={{ width: '100%' }}
      type="number"
      id="outlined-basic"
      label="Vigencia (años)"
      variant="outlined"
      InputProps={{ inputProps: { min: 1, max: 9 } }}
      value={formTrainingDataState.validity}
      onChange={event => {
        setValidity(parseInt(event.target.value));
      }} />
  );
}

function setupTotalHoursField(formTrainingDataState, setTotalHours) {
  return (
    <TextField
      style={{ width: '100%' }}
      type="number"
      id="outlined-basic"
      label="Horas Totales"
      variant="outlined"
      InputProps={{ inputProps: { min: 1, max: 200 } }}
      value={formTrainingDataState.totalHours}
      onChange={event => {
        setTotalHours(parseInt(event.target.value));
      }} />
  );
}

function setupDateStart(formTrainingDataState, setDateStart) {
  return (
    <DatePicker
      label="Inicia"
      value={dayjs(formTrainingDataState.dateStart)}
      slotProps={{
        textField: {
          helperText: 'MM / DD / AAAA',
        },
      }}
      onChange={(newValue) => setDateStart(newValue)} />
  );
}

function setupDateEnd(formTrainingDataState, setDateEnd) {
  return (
    <DatePicker
      label="Termina"
      value={dayjs(formTrainingDataState.dateEnd)}
      slotProps={{
        textField: {
          helperText: 'MM / DD / AAAA',
        },
      }}
      onChange={(newValue) => setDateEnd(newValue)} />
  );
}

function setupDeputyField(
  formTrainingDataState,
  deputies,
  setDeputy,
) {
  return (
    <>
      {
        formTrainingDataState.deputy == null ?
          <Typography className={style.warning} variant="caption" display="block" gutterBottom>
            Debes elegir a la persona que firmará la constancia
          </Typography>
          : <></>
      }
      <Autocomplete
        value={formTrainingDataState.deputy?.completeName ?? ''}
        onInputChange={(event, newInputValue) => {
          let deputy = deputies.filter(deputy => deputy.completeName == newInputValue)
          if (deputy.length > 0) {
            setDeputy(deputy[0])
          }
        }}
        id="controllable-states-demo"
        options={deputies.map(deputy => deputy.completeName)}
        renderInput={(params) => <TextField {...params} label="Representante" />}
      />
    </>
  );
}

function setupInstructorField(
  formTrainingDataState,
  instructors,
  setInstructor,
) {
  return (
    <Autocomplete
      value={formTrainingDataState.instructor.completeName ?? ""}
      onInputChange={(event, newInputValue) => {
        let instructor = instructors.filter(instructor => instructor.completeName == newInputValue)
        if (instructor.length > 0) {
          delete instructor[0].conflicted // remove conflicted key to let the hasDataChanged work properly
          setInstructor(instructor[0])
        }
      }}
      id="controllable-states-demo"
      options={instructors.map(instructor => instructor)}
      renderInput={(params) => <TextField {...params} label="Instructor" />}

      getOptionLabel={(option) => option.completeName ?? option}
      renderOption={(props, option, state) => {
        return <ListItemText
          {...props}
          key={option.id}
          primary={option.completeName}
          secondary={option.conflicted &&
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                style={{ display: 'inline', color: 'red', marginLeft: 6 }}
              >
                {` - conflicto`}
              </Typography>
            </React.Fragment>
          }
        />;
      }}
    />
  );
}

function setupCourseField(
  formTrainingDataState,
  courses,
  setCourse,
) {
  return (
    <Autocomplete
      value={formTrainingDataState.course.name}
      onInputChange={(event, newInputValue) => {
        let course = courses.filter(course => course.name == newInputValue)
        if (course.length > 0) {
          setCourse(course[0])
        }
      }}
      id="controllable-states-demo"
      options={courses.map(course => course.name)}
      renderInput={(params) => <TextField {...params} label="Curso" />}
    />
  );
}

const TrainingDetail = () => {

  const { id } = useParams();

  const {
    formTrainingDataState,
    getTraining,
    updateName,
    updateSuccess,
    updateFailure,
    handleClose,
    openPDFDialog,
    openDialog,
    closeDialog,
    removeStudents,
    dataHasChanged,
    onClickUpdate,
    students,
    addStudent,
    courses,
    setCourse,
    instructors,
    setInstructor,
    setDateStart,
    setDateEnd,
    setCode,
    setSPTTRCode,
    setValidity,
    setTotalHours,
    deputies,
    setDeputy,
    onDeleteTraining,
  } = useTrainings(FlowType.details);

  useEffect(() => {
    getTraining(id);
  }, [updateSuccess]);

  let hasStudents = () => formTrainingDataState.students?.length > 0 ?? false;

  let hasDeputy = () => formTrainingDataState.deputy != null;

  const [open, setOpen] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (continueDeleting) => {
    setOpen(false);
    if (continueDeleting) {
      onDeleteTraining();
      setDeleted(true);
    }
  };

  return (
    deleted
      ? <Navigate to="/admin/trainings" />
      : <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <SimpleDialog
          trainingDetail={formTrainingDataState}
          open={openPDFDialog}
          onClose={closeDialog}
        />
        <Toolbar />
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  disabled={dataHasChanged || !hasDeputy() || !hasStudents()}
                  variant="contained"
                  onClick={() => {
                    openDialog();
                  }}>
                  Generar Constancia
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!dataHasChanged}
                  variant="contained"
                  onClick={() => { onClickUpdate() }}
                >
                  Actualizar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Eliminar
                </Button>
                <Dialog
                  open={open}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Eliminar"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Esto eliminará por completo los registros de esta capacitación ¿Desea continuar?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>Cancelar</Button>
                    <Button onClick={() => handleDialogClose(true)} autoFocus>
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title variant="h6" gutterBottom>
                  Datos generales
                </Title>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    ID: {formTrainingDataState.id}
                  </Grid>
                  <Grid item xs={12}>
                    {setupCourseField(formTrainingDataState, courses, setCourse)}
                  </Grid>
                  <Grid item xs={6}>
                    {setupDateStart(formTrainingDataState, setDateStart)}
                  </Grid>
                  <Grid item xs={6}>
                    {setupDateEnd(formTrainingDataState, setDateEnd)}
                  </Grid>
                  <Grid item xs={12}>
                    {setupInstructorField(formTrainingDataState, instructors, setInstructor)}
                  </Grid>
                  <Grid item xs={12}>
                    {setupDeputyField(formTrainingDataState, deputies, setDeputy)}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {setupCodeField(formTrainingDataState, setCode)}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {setupCodeSPPTRField(formTrainingDataState, setSPTTRCode)}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {setupValidityField(formTrainingDataState, setValidity)}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {setupTotalHoursField(formTrainingDataState, setTotalHours)}
                  </Grid>
                  <Grid item xs={12}>
                    <TrainingStudentsTable
                      title={'Participantes'}
                      headers={['ID', 'Nombre', 'Apellido', 'CURP', 'Compañía']}
                      rows={formTrainingDataState.students}
                      removeStudents={removeStudents}
                      students={students}
                      addStudent={addStudent}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Capacitación actualizada
          </Alert>
        </Snackbar>
        <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Error, no se pudo actualizar los datos de la capacitación
          </Alert>
        </Snackbar>
      </Box>
  );
};

export default TrainingDetail;

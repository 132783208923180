import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '../pages/signin/SignIn';
import Dashboard from '../pages/dashboard/Dashboard';
import UserDetail from '../components/userDetail/UserDetail';
import MainContainer from '../components/dashboard/MainContainer';
import Users from '../components/users/Users';
import UserNew from '../components/userNew/UserNew';
import Trainings from '../components/trainings/Trainings';
import Students from '../components/students/Students';
import Courses from '../components/courses/Courses';
import Instructors from '../components/instructors/Instructors';
import InstructorDetail from '../components/instructorDetail/InstructorDetail';
import InstructorNew from '../components/instructorNew/InstructorNew';
import StudentNew from '../components/studentNew/StudentNew';
import StudentDetail from '../components/studentDetail/StudentDetail';
import CourseDetail from '../components/courses/CourseDetail';
import CourseNew from '../components/courses/CourseNew';
import TrainingDetail from '../components/trainings/TrainingDetail';
import TrainingNew from '../components/trainings/TrainingNew';
import Deputies from '../components/deputies/Deputies';
import DeputyDetails from '../components/deputies/DeputyDetail';
import DeputyNew from '../components/deputies/DeputyNew';
import Logs from '../components/logs/Logs';
import Companies from '../components/companies/Companies';
import CompanyDetail from '../components/companies/CompanyDetail';
import CompanyNew from '../components/companies/CompanyNew';
import MigrationsNew from '../components/MigrationsNew/MigrationsNew';
import SendRecovery from '../components/SendRecovery/SendRecover';
import ChangePassword from '../components/ChangePassword/ChangePassword';
import TokenConfirm from '../components/SendRecovery/TokenConfirm';
import Profile from '../components/Profile/Profile';

const routes = [
    { path: '/login', element: <SignIn /> },
    { path: '/login/recover', element: <SendRecovery /> },
    { path: '/login/recoverConfirm', element: <TokenConfirm /> },
    { path: '/login/changePassword/:id', element: <ChangePassword /> },
    { path: '/admin', element: <Dashboard innerComponent={<MainContainer />} /> },

    // training region
    { path: '/admin/trainings/:id', element: <Dashboard innerComponent={<TrainingDetail />} /> },
    { path: '/admin/trainings', element: <Dashboard innerComponent={<Trainings />} /> },
    { path: '/admin/trainings/new', element: <Dashboard innerComponent={<TrainingNew />} /> },

    // deputy region
    { path: '/admin/deputies/:id', element: <Dashboard innerComponent={<DeputyDetails />} /> },
    { path: '/admin/deputies', element: <Dashboard innerComponent={<Deputies />} /> },
    { path: '/admin/deputies/new', element: <Dashboard innerComponent={<DeputyNew />} /> },

    // courses region
    { path: '/admin/courses/:id', element: <Dashboard innerComponent={<CourseDetail />} /> },
    { path: '/admin/courses', element: <Dashboard innerComponent={<Courses />} /> },
    { path: '/admin/courses/new', element: <Dashboard innerComponent={<CourseNew />} /> },

    // companies region
    { path: '/admin/companies/:id', element: <Dashboard innerComponent={<CompanyDetail />} /> },
    { path: '/admin/companies', element: <Dashboard innerComponent={<Companies />} /> },
    { path: '/admin/companies/new', element: <Dashboard innerComponent={<CompanyNew />} /> },

    // teachers region
    { path: '/admin/instructors/:id', element: <Dashboard innerComponent={<InstructorDetail />} /> },
    { path: '/admin/instructors', element: <Dashboard innerComponent={<Instructors />} /> },
    { path: '/admin/instructors/new', element: <Dashboard innerComponent={<InstructorNew />} /> },

    // students region
    { path: '/admin/students/:id', element: <Dashboard innerComponent={<StudentDetail />} /> },
    { path: '/admin/students', element: <Dashboard innerComponent={<Students />} /> },
    { path: '/admin/students/new', element: <Dashboard innerComponent={<StudentNew />} /> },

    //migrations region
    { path: '/admin/students/migrateStudent', element: <Dashboard innerComponent={<MigrationsNew />} /> },

    //Profile region
    { path: '/admin/profile/:id', element: <Dashboard innerComponent={<Profile />} /> },

    // users region
    { path: '/admin/users/:id', element: <Dashboard innerComponent={<UserDetail />} /> },
    { path: '/admin/users', element: <Dashboard innerComponent={<Users />} /> },
    { path: '/admin/users/new', element: <Dashboard innerComponent={<UserNew />} /> },

    // logs region
    { path: '/admin/logs', element: <Dashboard innerComponent={<Logs />} /> },
    // default
    { path: '*', element: <Navigate to="/login" /> },
];

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((appRoute, index) => (<Route exact={appRoute.isExact} key={index + appRoute.path} path={appRoute.path} element={appRoute.element} />))}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;

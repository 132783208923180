import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'profile';

/* Retrieve one single user data */
async function getUser(userId) {
    const url = APIUrlBase + '/' + userId;
    return await callService(url);
}

/* Call API service to create a user */
async function updateUser(userId, data) {
    const url = APIUrlBase + '/' + userId;
    const method = 'PATCH';
    const payload = {
        name: `${data.name}`,
        email: data.email,
        roleId: data.type === 'Administrador' ? 1 : 2,
        password: data.password,
        isActive: data.isActive,
    }
    return await callService(url, method, payload);
}

export default { getUser, updateUser, };

import { useEffect, useReducer, useState } from "react";
import StudentService from "../../services/StudentService";

var initialDataMigrate = {
    id: "",
    name: "",
    lastname: "",
    curp: null,
    rfc: null,
    trainings: [],
    company: null,
    shift: null,
};

var initialDataDestination = {
    id: "",
    name: "",
    lastname: "",
    curp: null,
    rfc: null,
    trainings: [],
    company: null,
    shift: null,
};

const studentDataNull = {
    id: "",
    name: "",
    lastname: "",
    curp: null,
    rfc: null,
    trainings: [],
    company: null,
    shift: null,
}

function responseToStudentDetail(response) {
    return {
        id: response.id,
        name: response.name,
        lastname: response.lastname,
        curp: response.curp,
        rfc: response.rfc,
        trainings: createTraining(response.trainings),
        trainingsId: response.trainings ? response.trainings.map(training => training.id) : [],
        company: response.company,
        shift: response.shift,
    }
}

function createTraining(trainings) {
    const some = trainings.map((training) => {
        const newTraining = {
            ...training,
            dateStart: new Date(training.dateStart),
            dateEnd: new Date(training.dateEnd),
        };
        return newTraining;
    });
    return some;
}

function createStudentData(student) {
    return {
        id: student.id,
        name: student.name,
        lastname: student.lastname,
        completeName: student.name + ' ' + student.lastname,
        company: student.company ? student.company.name : "Sin empresa",
        shift: student.shift ? student.shift : "guardia no definida",
    }
}

const ACTION_INITIAL_MIGRATE = "ACTION_INITIAL_MIGRATE";
const ACTION_INITIAL_DESTINATION = "ACTION_INITIAL_DESTINATION";

const reducer = (state, payload) => {
    switch (payload.action) {
        case ACTION_INITIAL_MIGRATE:
            initialDataMigrate = payload.data
            return payload.data;
        case ACTION_INITIAL_DESTINATION:
            initialDataDestination = payload.data
            return payload.data;
        default:
            return state;
    }
}

const useMigrationsNew = () => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedStudentMigrate, setSelectedStudentMigrate] = useState(null);
    const [selectedStudentDestination, setSelectedStudentDestination] = useState(null);

    const [studentMigrateDetails, dispatchMigrate] = useReducer(reducer, initialDataMigrate);
    const [studentDestinationDetails, dispatchDestination] = useReducer(reducer, initialDataDestination);



    useEffect(() => {
        getStudentMigrate();
    }, [selectedStudentMigrate]);

    useEffect(() => {
        getStudentDestination();
    }, [selectedStudentDestination]);

    const getStudents = async () => {
        const response = await StudentService.getStudents();
        const students = response.map((student) => createStudentData(student));
        setStudents(students);
        setIsLoading(false);
        setInitialDataMigrate(responseToStudentDetail(studentDataNull));
        setInitialDataDestination(responseToStudentDetail(studentDataNull));

    };

    const getStudentMigrate = async () => {
        const studentMigrate = await StudentService.getStudent(selectedStudentMigrate.id)
        if (studentMigrate) {
            setInitialDataMigrate(responseToStudentDetail(studentMigrate));
        }
    };

    const getStudentDestination = async () => {
        const studentDestination = await StudentService.getStudent(selectedStudentDestination.id)
        if (studentDestination) {
            setInitialDataDestination(responseToStudentDetail(studentDestination));
        }
    };

    const setInitialDataMigrate = (data) => {
        dispatchMigrate({ action: ACTION_INITIAL_MIGRATE, data: data });
    }

    const setInitialDataDestination = (data) => {
        dispatchDestination({ action: ACTION_INITIAL_DESTINATION, data: data });
    }

    const handleClick = () => {
        setUpdateSuccess(true);
        setUpdateFailure(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const migrateStudent = async () => {
        const response = await StudentService.migrateStudent(studentMigrateDetails, studentDestinationDetails);
        if (response) {
            setUpdateSuccess(true);
            setInitialDataMigrate(responseToStudentDetail(initialDataMigrate));
            setInitialDataDestination(responseToStudentDetail(initialDataDestination));
            setRedirect(`/admin/migrateStudent`);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        studentMigrateDetails,
        studentDestinationDetails,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        selectedStudentMigrate,
        setSelectedStudentMigrate,
        selectedStudentDestination,
        setSelectedStudentDestination,
        migrateStudent,
        redirect,
        students,
        getStudents,
        getStudentMigrate,
        isLoading,
    }
};

export { useMigrationsNew };

import { useState, useEffect } from 'react';
import LoginService from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';



const useRecover = () => {
    const [logged, setLogged] = useState(false);
    const [noAuth, setNoAuth] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (LoginService.isLoggedIn()) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [logged]);


    const sendRecovery = async (email) => {
        const result = await LoginService.sendRecovery(email);
        if (result) {
            navigate('/login/recoverConfirm');
        } else {
            setNoAuth(false); 
        }
    }

    const tokenConfirm = async (token) =>{
        const tokenExist = await LoginService.tokenConfirm(token);
        if(tokenExist){
            navigate('/login/changePassword/'+tokenExist.id);
        }else{
            setNoAuth(false);
        }
    }

    const reset = () => {
        setNoAuth(null)
    }

    return {
        logged,
        noAuth,
        reset,
        sendRecovery,
        tokenConfirm,
    };
}

export { useRecover };
